import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { params } from "./util";
import { HexClient as client } from './client';
import * as ld from 'lodash';

export const TYPE = {
    PENDING: 'Pending',
    FAILED: 'Failed',
    PROCESSING: 'Processing',
    FETCHING: 'Fetching',
    ALEXA: 'Alexa',
    HUMAN: 'Human',
    APL: 'APL'
}

export const Item = (content, type, dialogId = '') => {
    return {
        id: uuidv4(),
        dialogId: dialogId,
        type: type,
        content: content,
        starttime: Math.floor(new Date().getTime() / 1000),
        utteranceId: ''
    }
};

const size = 50;

export function History(dsn, deviceType, env = () => "prod") {
    let history = [];
    let subscribers = [];

    this.dsn = dsn;
    this.type = deviceType;
    this.history = () => history;

    const publish = (item) => {
        subscribers.forEach(s => s.filter(item) && s.callback(this));
    };

    this.getItem = (id) => {
        return _.find(history, { id: id });
    }

    this.getItemByDialogId = (dialogId) => {
        return _.find(history, { dialogId: dialogId }, {});
    }

    this.addItem = (item) => {
        if (history.length === size) {
            history.shift();
        }
        history.push(item);
        publish(item);
        if (item.type === TYPE.FETCHING) { // Fetch ASR for manual utterances
            this.fetchUtterance(item);
        }
    }

    this.updateItem = (item) => {
        let index = history.findIndex(i => i.id === item.id);
        if (index < 0) {
            throw new Error(`Cannot find item with id ${item.id}`)
        }
        history[index] = { ...history[index], ...item };
        publish(item);
    }

    this.fetchUtterance = async (item) => {
        let param = params(this.dsn, this.type, {
            'dialogrequestid': item.dialogId,
            'approximateinjectiontime': item.starttime
        });

        // Add delay for data availability
        await new Promise(r => setTimeout(r, 10000));

        for (let i = 0; i < 3; i++) {
            try {
                await new Promise(r => setTimeout(r, 10000));
                let utteranceinfo = await client.utteranceinfo(param, env());
                let utteranceid = _.get(utteranceinfo, "data.UtteranceId");
                let activity = _.get(utteranceinfo, "data.Activity.ActivityItemData");
                let llmTrace = _.get(utteranceinfo, "data.LlmTrace");

                if (utteranceid) {
                    this.updateItem({ id: item.id, utteranceId: utteranceid });
                }
                if (llmTrace) {
                    let bytedata = JSON.parse(atob(llmTrace));
                    let utterance = _.get(bytedata, "value.utteranceInfo.utterance");
                    this.updateItem({ id: item.id, content: utterance, type: TYPE.HUMAN });
                    return;
                }
                if (activity) {
                    let parsed = JSON.parse(activity);
                    this.updateItem({ id: item.id, content: parsed.asrText, type: TYPE.HUMAN });
                    return;
                }
            } catch (error) {}
        }

        this.updateItem({ id: item.id, content: 'Cannot retrieve data!', type: TYPE.FAILED });
    }

    this.featureItems = () => {
        let featureItems = [];
        let filtered = history.filter(i => [TYPE.ALEXA, TYPE.HUMAN].includes(i.type));

        let pair = {};
        for (const i of filtered) {
            const content = i.content.replace(/"/g, '');
            // Push an entry for an answer
            if (i.type === TYPE.ALEXA) {
                pair["A"] = content;
                pair["Q"] && pair["A"] && featureItems.push({...pair});
                pair = {};
            }
            // Up to last Human for the Answer
            if (i.type === TYPE.HUMAN) {
                pair["Q"] = content;
            }
        }

        return featureItems;
    }

    this.clear = async () => {
        history = [];
    }

    this.subscribe = (callback, filter = i => true) => {
        subscribers.push({callback, filter});

        // Return unsubscribe
        return () => {
            const index = _.findIndex(subscribers, i => i.callback == callback);
            if (index !== -1) {
                subscribers.splice(index, 1);
            }
        };
    };
}
